<template>
  <div>
    <v-container v-if="componentLoading">
      <v-row justify="center">
        <v-col xs="12" xl="6">
          <h3>
            <span v-if="component_type == 'edit'"
              >Edit Deal Summary Information
              <v-progress-circular
                indeterminate
                class="ml-3"
                color="primary"
              ></v-progress-circular></span
            ><span v-if="component_type == 'add'">Create Deal Summary</span>
          </h3>
          <v-card v-if="error" class="danger mb-6">
            <v-card-subtitle class="red--text"
              >There was an error.</v-card-subtitle
            >
            <v-card-text class="red--text">{{ error }}</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="!componentLoading">
      <v-row v-if="copy_existing_summary" justify="center">
        <v-col xs="12" lg="6">
          <v-alert type="warning"
            >There are existing deal summaries for this deal. By creating a new
            summary, you will automatically archive the old deal summaries when
            saving. Archived deals will no longer display to lenders and this
            new deal summary will. Deal offers do not transfer from previous
            deal summaries. This is a "new" deal summary. The deal summary data
            has been copied from the most recent summary for ease of data
            entry.</v-alert
          >
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col xs="12" lg="6">
          <h3>
            <span v-if="component_type == 'edit'">Edit Deal Information</span>
            <span v-if="component_type == 'add'">Create Deal Summary</span>
          </h3>

          <v-form
            @submit="submitCreateDeal"
            ref="form"
            v-model="formRules.valid"
          >
            <v-row v-if="component_type == 'edit'">
              <v-col>
                <v-select
                  label="Deal Status"
                  v-model="deal_summary_data.status"
                  :items="deal_status_options"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  v-model="deal_summary_data.city"
                  label="City"
                  :rules="formRules.required"
                ></v-text-field>
                <v-select
                  :items="provinceOptions"
                  v-model="deal_summary_data.province"
                  label="Province"
                  :rules="formRules.required"
                ></v-select>
              </v-col>
              <v-col>
                <v-textarea
                  v-model="deal_summary_data.location_notes"
                  label="Location Notes"
                  type="number"
                  outlined
                  :rules="formRules.required"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-select
                  :items="property_type_options"
                  v-model="deal_summary_data.property_type"
                  label="Property Type"
                  :rules="formRules.required"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  v-model="deal_summary_data.property_value"
                  label="Property Value"
                  type="number"
                  :rules="formRules.required"
                ></v-text-field>
                <p class="caption">
                  Add any interesting notes which may be useful. Type of
                  property, how many units, etc. Recently renovated?
                </p>
              </v-col>
              <v-col>
                <v-textarea
                  v-model="deal_summary_data.property_value_notes"
                  label="Property Notes"
                  type="number"
                  outlined
                  :rules="formRules.required"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  v-model="deal_summary_data.owing_against"
                  label="Owing Against"
                  type="number"
                  :rules="formRules.required"
                ></v-text-field>
                <p class="caption">1st? 2nd? LOC? Any payouts in this?</p>
              </v-col>
              <v-col>
                <v-textarea
                  v-model="deal_summary_data.owing_against_notes"
                  label="Owing Against Notes"
                  type="number"
                  outlined
                  :rules="formRules.required"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  v-model="deal_summary_data.amount_requested"
                  label="Amount Requested"
                  type="number"
                  :rules="formRules.required"
                ></v-text-field>
                <p class="caption">1st? 2nd? LOC? Any payouts in this?</p>
                <v-text-field
                  v-model="deal_summary_data.mortgage_position"
                  label="Mortgage Position"
                  type="number"
                  :rules="formRules.required"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-textarea
                  v-model="deal_summary_data.amount_requested_notes"
                  label="Amount Requested Notes"
                  type="number"
                  outlined
                  :rules="formRules.required"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  v-model="deal_summary_data.loan_to_value"
                  label="Loan To Value"
                  type="number"
                  :rules="formRules.required"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="deal_summary_data.GDS"
                  label="Gross Debt Service Ratio (PITH)"
                  type="number"
                  :rules="formRules.required"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="deal_summary_data.TDS"
                  label="Total Debt Service Ratio (PITH + Other Liabilities)"
                  type="number"
                  :rules="formRules.required"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-textarea
                  v-model="deal_summary_data.income_notes"
                  label="Income Notes"
                  type="number"
                  outlined
                  :rules="formRules.required"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-textarea
                  v-model="deal_summary_data.liability_notes"
                  label="Liability Notes"
                  type="number"
                  outlined
                  :rules="formRules.required"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-textarea
                  v-model="deal_summary_data.credit_notes"
                  label="Credit Notes"
                  type="number"
                  outlined
                  :rules="formRules.required"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-textarea
                  v-model="deal_summary_data.summary_notes"
                  label="Summary"
                  type="number"
                  outlined
                  :rules="formRules.required"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  v-model="deal_summary_data.exit_strategy"
                  label="Exit Strategy"
                  type="number"
                  outlined
                  :rules="formRules.required"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  v-model="deal_summary_data.proposed_term"
                  label="Proposed Term (Months)"
                  :rules="formRules.required"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="deal_summary_data.proposed_rate"
                  label="Proposed Interest Rate (%)"
                  type="number"
                  :rules="formRules.required"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="deal_summary_data.proposed_lender_fee"
                  label="Proposed Lender Fee (%)"
                  type="number"
                  :rules="formRules.required"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-card v-if="submitError" class="danger mb-6">
                  <v-card-subtitle class="red--text"
                    >There was an error.</v-card-subtitle
                  >
                  <v-card-text class="red--text">{{ submitError }}</v-card-text>
                </v-card>
                <v-btn
                  v-if="component_type == 'add'"
                  :disabled="formLoading"
                  @click="submitCreateDeal"
                  >Create Deal
                  <v-progress-circular
                    indeterminate
                    class="ml-3"
                    color="primary"
                    v-if="formLoading"
                  ></v-progress-circular
                ></v-btn>
                <v-btn
                  v-if="component_type == 'edit'"
                  :disabled="formLoading"
                  @click="submit_update_deal_summary"
                  >Update Deal
                  <v-progress-circular
                    indeterminate
                    class="ml-3"
                    color="primary"
                    v-if="formLoading"
                  ></v-progress-circular
                ></v-btn>
                <v-btn
                  dark
                  class="ml-4"
                  v-if="component_type == 'edit'"
                  @click="
                    $router.go(-1)
                  "
                  >Cancel</v-btn
                >
                <v-btn
                  dark
                  class="ml-4"
                  v-if="component_type == 'add'"
                  color="red"
                  @click="$router.go(-1)"
                  >Back</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import API from "@/plugins/API";


export default {
  name: "DealAdd",
  props: ["deal_id", "deal_summary_id"],
  data() {
    return {
      component_type: "",
      deal_status_options: [
        "draft",
        "active",
        "shortlisted",
        "selected",
        "archive",
        "funded",
        "awarded",
      ],
      formLoading: false,
      componentLoading: true,
      error: "",
      submitError: "",
      alert_will_archive_old_summaries: false,
      copy_existing_summary: false,
      formRules: {
        valid: false,
        required: [(value) => !!value || "This is a required field."],
      },
      deal_summary_data: {
        city: "",
        province: "",
        location_notes: "",
        property_type: "",
        property_value: "",
        property_value_notes: "",
        owing_against: "",
        owing_against_notes: "",
        amount_requested: "",
        amount_requested_notes: "",
        mortgage_position: "",
        loan_to_value: "",
        GDS: "",
        TDS: "",
        income_notes: "",
        credit_notes: "",
        liability_notes: "",
        summary_notes: "",
        exit_strategy: "",
        proposed_term: "",
        proposed_rate: "",
        proposed_lender_fee: "",
        deal: this.deal_id,
      },
      provinceOptions: [
        { value: "BC", text: "British Columbia" },
        { value: "AB", text: "Alberta" },
        { value: "ON", text: "Ontario" },
      ],
      property_type_options: [
        { value: "land", text: "Bare Land" },
        { value: "townhouse", text: "Townhouse" },
        { value: "detached home", text: "Detached Home" },
        { value: "apartment", text: "Apartment" },
      ],
    };
  },
  methods: {
    submitCreateDeal() {

      console.log('submit create deal')
     
      this.formLoading = true;
      this.submitError = "";

      this.$refs.form.validate();
      console.log(this.$refs.form.validate())

      if (this.formRules.valid) {
      
        console.log('posting new deal summary')
 
        API()
          .post("/api/internal-admin/deal_summary", this.deal_summary_data)
          .then((res) => {
            this.formLoading = false;

            this.$router.push(`/dashboard/admin/deal/read/${res.data.deal.id}`);
            console.log(res);
          })
          .catch((e) => {
            // alert(e);
            this.submitError = e;
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
      }
    },
    submit_update_deal_summary() {
      this.formLoading = true;
      this.submitError = "";

      this.$refs.form.validate();

      if (this.formRules.valid) {
        API()
          .patch(`/api/internal-admin/deal_summary/`, this.deal_summary_data)
          .then((res) => {
            this.formLoading = false;

            this.$router.push(`/dashboard/admin/deal/read/${res.data.deal.id}`);
          })
          .catch((e) => {
            // alert(e);
            this.submitError = e;
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
      }
    },

    async get_deal_information() {
      try {
        console.log('get deal information')
        let deal_information = await API().get(
          `/api/internal-admin/dealRead/${this.deal_id}`
        );

        console.log(deal_information)

        if (deal_information.data.deal_summaries.length) {
          this.copy_existing_summary = true;
          // can be 0 because it is sorted by createdAt desc
          let copy_summary = deal_information.data.deal_summaries[0];

          let allowed_keys = [
            "city",
            "province",
            "location_notes",
            "property_value",
            "property_value_notes",
            "owing_against",
            "owing_against_notes",
            "amount_requested",
            "amount_requested_notes",
            "mortgage_position",
            "loan_to_value",
            "GDS",
            "TDS",
            "income_notes",
            "credit_notes",
            "summary_notes",
            "exit_strategy",
            "proposed_term",
            "proposed_rate",
            "proposed_lender_fee",
            "property_type",
            "liability_notes",
          ];

          Object.keys(copy_summary).forEach((key) => {
            if (!allowed_keys.includes(key)) {
              delete copy_summary[key];
            }
          });

          this.alert_will_archive_old_summaries = true;

          this.deal_summary_data = copy_summary;
          this.deal_summary_data.deal = this.deal_id;

          this.componentLoading = false;
        } else {
          this.componentLoading = false;
        }
      } catch (error) {
        console.log("error happened pulling deal information");
        console.log(error);
      }
    },
  },
  async mounted() {

    if (this.$route.name == "deal_summary_add") {
      this.component_type = "add";
      console.log('deal summary add')
      await this.get_deal_information();
    } else if (this.$route.name == "deal_summary_edit") {
      this.component_type = "edit";

      // get the deal summary information including deal

      API()
        .get(
          `/api/internal-admin/deal_summary_read_for_edit/${this.deal_summary_id}`
        )
        .then((res) => {
          this.deal_summary_data = res.data;
          this.componentLoading = false;
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status == 401) {
              this.$store.dispatch("Auth/silentLogin");
            }
          }
          console.log(e);
        });
    }
  },
};
</script>

<style>
</style>